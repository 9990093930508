'use client';

import { usePathname } from 'next/navigation';
import Logo from '~/shadcn/logo';

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = ['/sv', '/en'].includes(pathname);

  const simpleFooter = (
    <footer className="pt-6 pb-5 text-center border-t border-b-muted-foreground">
      <div className="container mx-auto">
        <Logo className="w-20 h-20 mb-3 mx-auto" />

        <div className="text-xs text-foreground">
          © {new Date().getFullYear()} All rights reserved
          <br /> Stiftelsen Kempe-Carlgrenska Fonden &nbsp;
        </div>
      </div>
    </footer>
  );

  const mainFooter = (
    <footer className="bg-background pt-6 pb-5 border-t border-b-muted-foreground">
      <div className="container mx-auto">
        <div className="text-xs text-center text-foreground">
          © {new Date().getFullYear()}. All rights reserved. Stiftelsen
          Kempe-Carlgrenska Fonden.
        </div>
      </div>
    </footer>
  );

  return isHome ? simpleFooter : mainFooter;
}
