import React from 'react';
import NextLink from 'next/link';
import Image from 'next/image';
import { cn } from '~/lib/utils';

interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  disabledLink?: boolean;
  innerRef?: React.Ref<HTMLImageElement>;
}

const Logo: React.FC<LogoProps> = ({
  disabledLink = false,
  className,
  innerRef,
  ...other
}) => {
  const logo = (
    <Image
      ref={innerRef}
      src="/logo/logo_single.svg"
      alt="KC-Fonden"
      width={40}
      height={40}
      className={cn(
        'min-w-10 min-h-10 cursor-pointer: hover:opacity-80',
        className
      )}
      {...other}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return <NextLink href="/">{logo}</NextLink>;
};

export default Logo;
